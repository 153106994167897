@import url("https://fonts.googleapis.com/css?family=Maven Pro");

/* body {
    background-image: linear-gradient(to bottom right,rgb(223, 237, 240), rgb(213, 217, 255));
    font-family: 'Maven Pro', serif;
    padding-top: 55px;
    height: 100vh;
} */

section {
  background: linear-gradient(
    to top left,
    #68f1fc 2%,
    #0082ff 44%,
    #202e79 100%
  );
  font-family: "Maven Pro", serif;
  height: 100%;
  overflow: auto;
}

.custom-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
}

.custom-grid-col1,
.custom-grid-col2 {
  display: flex;
  flex-direction: column;
}

.custom-grid-col1 {
  justify-content: center;
  align-items: center;
}

.custom-grid-col2 {
  justify-content: center;
  align-items: center;
}

.flight-census-logo-container {
  display: none;
}

.logo_container {
  align-self: center;
  max-width: 300px;
}

.logo_container img {
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to top left, #ffffff 2%, #ffffff 43%);
  padding: 3.5rem 2.5rem;
  border-radius: 50px;
  gap: 3rem;
}

.inputs_container input {
  background-color: #92afd3;
  font-size: 1.2em;
  border-radius: 25px !important;
}

.inputs_container,
.btns_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.btns_container {
  align-self: center;
  width: 80%;
}

#btnEntrar {
  border: none;
  padding: 0.5rem;
}

.mascot_container {
  position: relative;
}

.mascot {
  position: absolute;
  height: 280px;
  top: -155px;
  left: -157px;
}

@media (max-width: 767px) {
  .custom-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .custom-grid-col2 form {
    padding: 2.5rem;
  }

  .flight-census-logo-container {
    display: flex;
    justify-content: center;
  }

  .flight-census-logo-container img {
    max-width: 40vw;
    margin-bottom: 1rem;
  }

  .drone-container {
    display: none;
  }

  .mascot {
    display: none;
  }
}
