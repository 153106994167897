/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext'); */
@import url('https://fonts.googleapis.com/css?family=Maven Pro');

/* @font-face {
    font-family: "Maven Pro";
    src: url("https://fonts.googleapis.com/css?family=Maven Pro") format("woff2"),
      url("https://fonts.googleapis.com/css?family=Maven Pro") format("woff");
    font-weight: bold;
    font-display: swap;
} */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-family: "Maven Pro", "Arial"; */
    font-family: 'Montserrat';
    font-weight: 400;
}

body {
    /* background-color: #c0c0c0; */
    /* background-color:  rgb(188, 190, 204); */
    /* background-image: linear-gradient(to bottom right,rgb(223, 237, 240), rgb(213, 217, 255)) !important; */
    /* background-color: #f2f6fd; */
    background-color: #ecf0f3;
    /* font-family: 'Maven Pro', serif; */
    /* padding-top: o; */
    /* height: 100vh; */
    /* padding-bottom: 50px; */
    color: #3c3f49;
    /* overflow: hidden; */
}

.container {
    margin-bottom: 48px;
}
a {
    color: rgb(49, 49, 49);
    text-decoration: none;
}

/* 
a:link {
    color: red;
} */

/* visited link */
/* a:visited {
} */

/* mouse over link */
a:hover {
    color: rgb(122, 122, 122);
}

/* selected link */
/* a:active {
} */

.btn-primary {
    background-color: rgb(33, 46, 196);
}

.btn-item-list {
    border-radius: 20px;
    margin: 5px;
    background-color: #eaf0f6;
    height: 40px;
    width: 40px;
    color: #656e79 !important;
    display: "block";
}

.btn-item-list:hover {
    background-color: #c1c5ca
}

.nav-header {
    color: #5a5a5a;
    background-color: #ffffffd8;
    border: 1px rgb(226, 226, 226) solid;
}

.navbar {
    /* position: absolute; */
    /* width:100%; */
    transition: background-color 0.5s;
}

.navbar:hover {
    background-color: #ffffff !important;
}

.listItem {
    background-color: #fff;
}

.listItem:nth-child(even) {
    background-color: #ccc !important;
}

.rect {
    width: 100%;
    height: 100vh;
}

#green {
    background-color: #559065;
}

#blue {
    background-color: #7eb3a7;
}

.bg-white-50 {
    background-color: rgba(255, 255, 255, 0.3) !important
}

.nav-profile-photo {
    cursor: pointer;
    margin-left: 20px;
    margin-right: 10px;
}

.nav-profile-photo div:hover {
    box-shadow: 0px 0px 8px #c0c0c0;
    text-shadow: 0px 0px 8px #c0c0c0;
}

.nav-profile-photo div {
    border-radius: 50%;
    height: 100%;
    min-height: 40px;
    min-width: 40px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;
}

.icon-profile {
    position: relative;
    left: 40px;
    top: 10px
}

.nav-button-circle {
    box-shadow: 0px 0px 4px #c0c0c0;
    /* border-radius: 50%; */
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    margin-left: 20px;
    margin-right: 20px;
}

.nav-button-circle:hover {
    box-shadow: 0px 0px 8px #c0c0c0;

}

.vertical-separator {
    border-left: 1px solid #c0c0c0;
    height: 40px;
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0em;
    vertical-align: 0em;
    content: null;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
}

div:has( > #listaPontos) {
    z-index: 1030 !important;
}

.menu-lateral-unhover {
    height: fit-content;
    max-height: 70vh;
    width: 150px;
    position: fixed;
    top: 80px;
    left: 15px;
    bottom: 10px;
    border-radius: 10px;
    transition: width 0.1s linear, background-color 1s;
    box-shadow: 0px 0px 8px rgba(163, 163, 163, 0.7);
    background-color: rgba(230, 230, 230, .85);
    z-index: 1040;

}
.menu-lateral-right {
    height: fit-content;
    max-height: 80vh;
    width: 65px;
    position: fixed;
    top: 65px;
    right: 15px;
    border-radius: 10px;
    transition: width 0.1s linear, background-color 1s, right .5s;
    box-shadow: 0px 0px 8px rgba(163, 163, 163, 0.7);
    background-color: rgba(230, 230, 230, .85);
    z-index: 1040;
    overflow-y: hidden;
}

/* .menu-lateral-right:hover {
    background-color: white !important;
    width: 280px;
    transition: width .5s;
    overflow-y: scroll;
} */

.item-menu-right {
    display: flex;
    overflow: hidden;
    cursor: pointer;
}

.item-menu-right p {
    width: 100%;
    text-align: right;
}


.menu-lateral h3:hover {
    box-shadow: 0px 0px 8px rgba(163, 163, 163, 0.7);

}

.menu-lateral {
    height: fit-content;
    max-height: 80vh;
    width: 75px;
    /* width: auto; */
    position: fixed;
    top: 65px;
    left: 15px;
    border-radius: 10px;
    transition: width 0.1s linear, background-color 1s, left .5s;
    box-shadow: 0px 0px 8px rgba(163, 163, 163, 0.7);
    background-color: rgba(230, 230, 230, .85);
    z-index: 1040;
    overflow-y: scroll;
}
/* .menu-lateral:hover {
    background-color: white !important;
    //width: 280px;
    width: auto;
    transition: width .5s;
    overflow-y: scroll;
} */
.menu-lateral-keep-open {
    background-color: white !important;
    width: auto;
    transition: width .5s;
    overflow-y: scroll;
}

.menu-lateral-controles-mapa {
    height: 85px;
    width: 65px;
    position: fixed;
    top: 10vh;
    right: 45px;
    border-radius: 10px;
    transition: width 0.1s linear, background-color 1s;
    box-shadow: 0px 0px 8px rgba(163, 163, 163, 0.7);
    background-color: rgba(230, 230, 230, .85);
    z-index: 1000;

}

.menu-lateral-controles-mapa:hover {
    background-color: white !important;
    width: 250px;
    height: 80vh;
    transition: width .5s, height .5s;
}

.secondary-menu {
    height: fit-content;
    /* max-width: 300px; */
    position: fixed;
    top: 65px;
    left: 100px;
    border-radius: 10px;
    transition: width 0.1s linear, background-color 1s, all 1s;
    box-shadow: 0px 0px 8px rgba(163, 163, 163, 0.7);
    background-color: rgba(230, 230, 230, .85);
    z-index: 1040;
    overflow-y: hidden;
}

.secondary-menu-right {
    height: fit-content;
    /* max-width: 300px; */
    /* min-width: calc("100vh - 0px"); */
    position: fixed;
    top: 65px;
    right: 100px;
    border-radius: 10px;
    transition: width 0.1s linear, background-color 1s, all 1s;
    box-shadow: 0px 0px 8px rgba(163, 163, 163, 0.7);
    background-color: rgba(230, 230, 230, .85);
    z-index: 1040;
    overflow-y: hidden;
}

.menu-left-portrait{
    left: -90px
}

.menu-right-portrait{
    right: -90px
}

.menu-lateral-left-portrait-open{
    left: 10px;
}

.left-menu-arrow-control{
    position: absolute;
    left: 0px;
    top: 65px;
    height: 10vh;
    z-index: 1000;
    transition: left .5s;
}
.left-menu-arrow-control-open{
    left: 90px;
}


.menu-lateral-right-portrait-open{
    right: 15px;
}


.right-menu-arrow-control{
    position: absolute;
    right: 0px;
    top: 65px;
    height: 10vh;
    z-index: 1000;
    transition: right .5s;
}
.right-menu-arrow-control-open{
    right: 90px;
}

.main-content {
    background-color: white;
    border-radius: 10px;
    margin-top: 30px;
    text-align: justify;
    padding-bottom: 10px;
}

.logo-menu {
    background-size: auto;
    background-position: center;
    width: 90%;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.icon-menu {
    width: 60px;
}

.item-menu {
    display: flex;
    overflow: hidden;
    cursor: pointer;
}

.item-menu p {
    width: 100%;
    text-align: left;
}

.borda-lateral {
    border: #000 1px solid;
}

/* map */
.leaflet-control-layers {
    top: 10vw;
}

.leaflet-control-zoom {
    position: absolute;
    top: 5vw;
    left: 96vw;
}

.dmap {
    background-color: #559065;
    position: absolute;
    width: 350px;
    height: 500px;
    top: 10vw;
    z-index: 999;
    display: flex;
}

/* Css Login */

.fundo-teste {
    background-color: transparent !important;
    display: flex;
}

.login {
    background-color: transparent !important;
    padding-top: 25vh;
}

.cabecalho {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.lado_logo {
    display: flex;
    height: 100%;
    /* max-height: fit-content; */
    justify-content: center;
    background-image: url("https://static.wixstatic.com/media/5b9682_179ddc6ad7354b8389e5cc56af31f1c5~mv2.jpg/v1/fill/w_980,h_174,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/5b9682_179ddc6ad7354b8389e5cc56af31f1c5~mv2.jpg");
    align-items: flex-end;
}

.corpo {
    /* width: 500px; */
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botao_confirmar {
    display: flex;
    justify-content: center;
}

.form-login {
    background-color: #e6e6e6;
    box-shadow: 0px 0px 30px #bdbdbd;

    /* border-radius: 30px; */
}

.cabecalho-perfil {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 5px;
}

.recuadoOver {
    margin-left: 130px !important;
    transition: all 0.4s ease-out;
}

.recuadoOut {
    margin-left: 0px;
    transition: all 0.4s ease-out;
    /* padding-bottom: 14px */
}

/* empresas */
.subtiutlo-empresa {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.grupo-tabelas {
    overflow-y: scroll;
    height: 200px;
    max-height: 500px;
}




.btn-primary {
    background-color: rgb(33, 46, 196);
}

.rect {
    width: 100%;
    height: 100vh;
}

#green {
    background-color: #559065;
}

#blue {
    background-color: #7eb3a7;
}

.bg-white-50 {
    background-color: rgba(255, 255, 255, 0.3) !important
}

.nav-profile-photo {
    cursor: pointer;
    margin-left: 20px;
    margin-right: 10px;
}

.nav-profile-photo div:hover {
    box-shadow: 0px 0px 8px #c0c0c0;
    text-shadow: 0px 0px 8px #c0c0c0;
}

.nav-profile-photo div {
    border-radius: 50%;
    height: 100%;
    min-height: 40px;
    min-width: 40px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;
}

.icon-profile {
    position: relative;
    left: 40px;
    top: 10px
}

.nav-button-circle {
    box-shadow: 0px 0px 4px #c0c0c0;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    margin-left: 20px;
    margin-right: 20px;
}

.nav-button-circle:hover {
    box-shadow: 0px 0px 8px #c0c0c0;

}

.vertical-separator {
    border-left: 1px solid #c0c0c0;
    height: 40px;
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0em;
    vertical-align: 0em;
    content: null;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
}

.main-content {
    background-color: white;
    border-radius: 10px;
    margin-top: 30px;
    text-align: justify;
    padding-bottom: 10px;
}

.logo-menu {
    background-size: auto;
    background-position: center;
    width: 90%;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.icon-menu {
    width: 60px;
}

.item-menu {
    display: flex;
    overflow: hidden;
    cursor: pointer;
}

.item-menu p {
    width: 100%;
    text-align: left;
}

.borda-lateral {
    border: #000 1px solid;
}

/* map */
.leaflet-control-layers {
    top: 10vw;
}

.leaflet-control-zoom {
    position: absolute;
    top: 5vw;
    left: 96vw;
}

.dmap {
    background-color: #559065;
    position: absolute;
    width: 350px;
    height: 500px;
    top: 10vw;
    z-index: 999;
    display: flex;
}

/* Css Login */

.fundo-teste {
    background-color: transparent !important;
    display: flex;
}

.cabecalho {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.lado_logo {
    display: flex;
    height: 100%;
    /* max-height: fit-content; */
    justify-content: center;
    background-image: url("https://static.wixstatic.com/media/5b9682_179ddc6ad7354b8389e5cc56af31f1c5~mv2.jpg/v1/fill/w_980,h_174,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/5b9682_179ddc6ad7354b8389e5cc56af31f1c5~mv2.jpg");
    align-items: flex-end;
}

.corpo {
    /* width: 500px; */
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botao_confirmar {
    display: flex;
    justify-content: center;
}

.form-login {
    background-color: #e6e6e6;
    box-shadow: 0px 0px 30px #bdbdbd;

    /* border-radius: 30px; */
}

.cabecalho-perfil {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 5px;
}

.recuadoOver {
    margin-left: 130px !important;
    transition: all 0.4s ease-out;
}

.recuadoOut {
    margin-left: 0px;
    transition: all 0.4s ease-out;
}

/* empresas */
.subtiutlo-empresa {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.grupo-tabelas {
    overflow-y: scroll;
    height: 200px;
    max-height: 500px;
}

/* Scrollbar Customizada */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.bubble::after {
    content: '';
    border-bottom-left-radius: 50% 100%;
    border-bottom-right-radius: 50% 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
    background-color: #0f0f10;
    height: 85%;
}


.wrapper {
    position: absolute;
    /* left: 50%;
    top: 50%; */
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);
    overflow: hidden;
  }
  
  .wave {
    width: 1000px;
    height: 1025px;
    position: absolute;
    top: -25%;
    left: 50%;
    margin-left: -500px;
    margin-top: -500px;
    border-radius: 35%;
    background: rgba(255, 255, 255, .75);
    animation: wave 15s infinite linear;
    transform: rotate(185deg)
  }
  
  .no-select {
    -webkit-user-select:none;  
    -moz-user-select:none;     
    -ms-user-select:none;      
    user-select:none;  
  } 

  .mouseMove {
    cursor: crosshair;
  }